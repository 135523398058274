import React, { useContext, useState } from "react";

import { createContext } from "react";
import { IPlan } from "~/types/billing";

interface IPlanContextProps {
  plan: IPlan | null;
  setPlan: React.Dispatch<React.SetStateAction<IPlan | null>>;
}

export const PlanContext = createContext<IPlanContextProps>({
  plan: null,
  setPlan: () => {},
});

export const usePlan = (): IPlanContextProps => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error("A Plan must be setted");
  }
  return context;
};

interface IProps {
  children: React.ReactNode;
}

export const PlanProvider: React.FC<IProps> = ({ children }) => {
  const [plan, setPlan] = useState<IPlan | null>(null);

  return (
    <PlanContext.Provider value={{ plan, setPlan }}>
      {children}
    </PlanContext.Provider>
  );
};
