export enum EQuizLocation {
  GLOBAL = "GB",
  EUROPE = "EU",
  NORTH_AMERICA = "NA",
  ASIA = "AS",
}

export enum EQuizPersonality {
  INNOVATIVE = "IN",
  MODERN = "MO",
  ARTISTIC = "AR",
  LUXURIOUS = "LU",
}

export enum EQuizColorPalette {
  COOL = "CO",
  WARM = "WA",
  EARTHY = "EA",
  NEUTRAL = "NE",
}

export interface IQuizChoice {
  value: QuizAnswer;
  label: string;
  description?: string;
  image?: string;
  children?: IQuizChoice[];
  fullWidth?: boolean;
}

export interface IQuizCategory {
  category_id: string;
  category_name: string;
  parent_category_name?: string;
  parent_category_id?: string;
}

export type QuizAnswer = string | null | undefined;
export type QuizStep = {
  value: string;
  label: string;
  subtitle: string;
  childLabel?: string;
  canSkip?: boolean;
  canSkipChild?: boolean;
  hasChildren?: boolean;
  choices?: IQuizChoice[];
};

export interface IQuizTestimonial {
  image: string;
  quote: React.ReactElement;
  quoteBy: string;
}

export interface IQuizResult {
  plan_id?: string;
  store_theme?: string | undefined;
  category_id?: QuizAnswer;
  color_palette?: EQuizColorPalette;
  location_area: EQuizLocation;
  personality?: EQuizPersonality;
  stripe_payment_method_id?: string;
  spocket_paid_subscription: boolean;
  email?: string;
  id?: string | number;
}
