import styled from "styled-components";
import { getColor, getSize, responsive } from "~/spocketUI/helpers/style";
import { sidebarMobileHeight, sidebarWidth } from "./Sidebar.style";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  width: 100vw;
  left: 0;
  top: ${sidebarMobileHeight}px;
  position: fixed;
  padding: ${getSize(1)};
  background-color: ${getColor("background")};

  ${responsive("tablet")} {
    padding: ${getSize(5.8)} ${getSize(9.2)};
    width: calc(100vw - ${sidebarWidth}px);
    top: 0;
    left: ${sidebarWidth}px;
  }
`;
