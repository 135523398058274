import { lazy } from "react";

import { quiz } from "~/helpers/routes";
import { paths } from "~/router/paths";

const QuizPage = lazy(() => import("~/pages/quiz"));
const CheckoutPage = lazy(() => import("~/pages/checkout"));
const ConfirmationPage = lazy(() => import("~/pages/confirmation"));
const ErrorPage = lazy(() => import("~/pages/error"));

export const routes: Array<any> = [
  {
    path: paths.quiz.index,
    element: quiz(QuizPage),
  },
  {
    path: paths.quiz.checkout,
    element: quiz(CheckoutPage),
  },
  {
    path: paths.quiz.confirmation,
    element: quiz(ConfirmationPage),
  },
  {
    path: paths.quiz.error,
    element: quiz(ErrorPage),
  },
];
