import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { buildNumber, currentEnv, googleGAKey, isDevelopment, sentryDSN } from "./environment";
import ReactGA from "react-ga4";
import { useEffect } from "react";

export const analyticsSetup = () => {
  if (isDevelopment) return;

  ReactGA.initialize(googleGAKey, {
    gaOptions: {
      allowLinker: true,
      cookieDomain: "auto",
    },
  });

  // Google Analytics Consent
  ReactGA.gtag("consent", "default", {
    ad_storage: "granted",
    ad_user_data: "granted",
    ad_personalization: "granted",
    analytics_storage: "granted",
  });

  // ReactGA.ga("require", "linker");
  // ReactGA.ga("linker:autoLink", ["storeify.com", "storeify.ca"]);

  Sentry.init({
    dsn: sentryDSN,
    release: buildNumber.toString(),
    environment: currentEnv,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    integrations: [
      new Sentry.Replay(),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
  });
};

export const getCreateRouterWithSentry = () => {
  if (isDevelopment) return createBrowserRouter;

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  return sentryCreateBrowserRouter;
};
