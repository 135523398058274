import { createGlobalStyle } from "styled-components";
import { getBorderRadius, getColor } from "./spocketUI/helpers/style";

const GlobalStyle = createGlobalStyle`
:root {
    font-family: Inter;
    font-size: 16px;
    line-height: 150%;
    font-weight: 400;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    letter-spacing: 0.005em;
    color: ${getColor("text")};
}

* {
  box-sizing: border-box;
}
  
body, #app {
    margin: 0;
    display: block;
    min-width: 320px;
    min-height: 100vh;
    background-color: ${getColor("background")};
}

&&&.Toastify__toast-container {
  width: 450px;
}

.Toastify__toast {
  border-radius: 6px;
  width: "450px";
}

.Toastify__close-button{
    padding: 20px;
    opacity: 0.6;
  }

  .Toastify__toast{
    border-radius: 6px;
    border-left: solid 11px #e74c3c;
  }
`;

export default GlobalStyle;
