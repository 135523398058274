// @ts-nocheck
import {
  IThemeColors,
  ITheme,
  IThemeBreakpoints,
  ThemeShadows,
} from "~/spocketUI/types/theme";

export const colors: IThemeColors = {
  background: "#FAFAFA",
  backgroundSecondary: "#ffffff",
  text: "#080E28",
  textSecondary: "#8381A6",
  primary: "#2F3CDB",
  secondary: "#FFCD4B",
  disabled: "#e0e0e0",
  error: "#f44336",
  success: "#00A825",
  warning: "#ff9800",
  info: "#252b95",
  sidebar: "#ffffff",
  border: "#dfdfdf",
  borderSecondary: "#EEF2F6",
  white: "#ffffff",
  red: "#F44336",
  redSecondary: "#FFEBEE",
  green: "#00A825",
  yellow: "#FFC107",
  greenSecondary: "#E8F5E9",
  yellowSecondary: "#FFFDE7",
};

export const shadows: ThemeShadows = {
  xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
  sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  xxl: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  xxxl: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
};

export const breakpoints: IThemeBreakpoints = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "1440px",
};

const theme: ITheme = {
  colors,
  shadows,
  breakpoints,
};

export default theme;
