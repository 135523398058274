import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EQuizColorPalette, EQuizLocation, EQuizPersonality, IQuizResult } from "~/types/quiz";
import { useIntercom } from "react-use-intercom";

type QuizErrors = {
  email?: string;
  stripe_payment_method_id?: string;
};

interface QuizResultContextValue {
  quizResult: IQuizResult;
  setQuizResult: React.Dispatch<React.SetStateAction<IQuizResult>>;
  errors: QuizErrors;
  setErrors: React.Dispatch<React.SetStateAction<QuizErrors>>;
  theme: string | null | undefined;
  setTheme: React.Dispatch<React.SetStateAction<string | undefined | null>>;
}

interface QuizResultProviderProps {
  children: React.ReactNode;
}

const QuizResultContext = createContext<QuizResultContextValue | undefined>(undefined);

export const useQuizResult = (): QuizResultContextValue => {
  const context = useContext(QuizResultContext);
  if (!context) {
    throw new Error("useQuizResult must be used within a QuizResultProvider");
  }
  return context;
};

export const QuizResultProvider: React.FC<QuizResultProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<string | undefined | null>(null);

  const { update } = useIntercom();

  const [quizResult, setQuizResult] = useState<IQuizResult>({
    category_id: null,
    color_palette: EQuizColorPalette.COOL,
    location_area: EQuizLocation.GLOBAL,
    personality: EQuizPersonality.MODERN,
    email: undefined,
    id: undefined,
    spocket_paid_subscription: true,
  });

  const [errors, setErrors] = useState<QuizErrors>({
    email: undefined,
    stripe_payment_method_id: undefined,
  });

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get("email");
    if (emailParam) {
      const decodedEmailParam = decodeURIComponent(emailParam);
      // Update email in Intercom
      update({
        email: decodedEmailParam,
        company: {
          name: "DropGenius",
          companyId: "dropgenius",
        },
      });
      setQuizResult((prevState) => ({
        ...prevState,
        email: decodedEmailParam,
      }));
    }
  }, [location.search]);

  return (
    <QuizResultContext.Provider
      value={{
        quizResult,
        setQuizResult,
        errors,
        setErrors,
        theme,
        setTheme,
      }}
    >
      {children}
    </QuizResultContext.Provider>
  );
};
